import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Pagination,
  Container,
  Typography,
  Divider,
  CircularProgress,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [searchTerm, setSearchTerm] = useState(
    sessionStorage.getItem("currentTerm") || ""
  );
  const [searchResults, setSearchResults] = useState([]);
  const [page, setPage] = useState(
    parseInt(sessionStorage.getItem("currentPage")) || 1
  );
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProducts();
    sessionStorage.setItem("currentPage", page);
    sessionStorage.setItem("currentTerm", searchTerm);
    console.log(searchResults);
  }, [searchTerm, page]);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      let url;
      if (/^\d+$/.test(searchTerm)) {
        // buscar por PLU
        url = `https://spx.rtitec.com.ar:8080/api/products?plu=${searchTerm}&sort=NO_PLU&limit=6&page=${page}`;
      } else {
        //buscar por nombre
        url = `https://spx.rtitec.com.ar:8080/api/products?name=${searchTerm}&sort=NO_PLU&limit=6&page=${page}`;
      }
      const response = await axios.get(url);
      if (response.status === 200) {
        if (response.data.rows === null) {
          setSearchResults([]);
        } else {
          setTotalPages(response.data.totalPages);
          setSearchResults(response.data.rows);
        }

        setError(false);
      } else {
        console.error("Error al obtener los productos:", response.statusText);
        setErrorMsg(response.statusText);
        setError(true);
      }
    } catch (error) {
      console.error("Error al obtener los productos:", error.message);
      setErrorMsg(error.message);
      setError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const resetSearch = () => {
    setPage(1);
    setSearchTerm("");
  };
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const verProducto = (product) => {
    navigate(`/product/${product.productPLU}`, { state: { product } });
    console.log(product);
  };

  return (
    <Container>
      <h1>Productos</h1>
      <TextField
        fullWidth
        placeholder="Busca por nombre o num PLU"
        variant="outlined"
        InputProps={{
          startAdornment: <SearchIcon />,
          endAdornment: (
            <Button
              onClick={resetSearch}
              color="inherit"
              sx={{ cursor: "pointer" }}
            >
              <CancelIcon />
            </Button>
          ),
        }}
        value={searchTerm}
        onChange={handleSearchChange}
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "20rem",
          }}
        >
          <CircularProgress size="5rem" color="primary" />
        </div>
      ) : (
        <List>
          {!error ? (
            searchResults.length !== 0 ? (
              searchResults.map((product) => (
                <>
                  <Divider component="li" />

                  <ListItem
                    key={parseInt(product.productPLU)}
                    sx={{ padding: "10", cursor: "pointer" }}
                    onClick={() => verProducto(product)}
                  >
                    {/* IMAGEN */}
                    <img
                      src={`https://spx.rtitec.com.ar:8080/api/products/image/${product.productPLU}/front`}
                      alt={product.productName}
                      style={{
                        width: "5.7rem",
                        height:'6rem'
                      }}
                      onError={(e) => {
                        e.target.src =
                          "https://user-images.githubusercontent.com/24848110/33519396-7e56363c-d79d-11e7-969b-09782f5ccbab.png";
                      }}
                    />

                    {/* DESCRIPCION ITEM */}
                    <ListItemText
                      primary={
                        <Typography
                          variant="h6"
                          component="h6"
                          sx={{ color: "#0480e2", fontWeight: "bold" }}
                        >
                          {product.productName}
                        </Typography>
                      }
                      secondary={
                        <span style={{ fontWeight: "bold" }}>
                          ${product.precioVenta.toFixed(2)}
                        </span>
                      }
                      sx={{ marginLeft: "3%" }}
                    />
                  </ListItem>
                </>
              ))
            ) : (
              <h2>No se encontro el producto con la busqueda "{searchTerm}"</h2>
            )
          ) : (
            <h1 style={{ color: "red" }}>{errorMsg}</h1>
          )}
        </List>
      )}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          count={totalPages}
          page={page}
          siblingCount={0}
          color="primary"
          variant="outlined"
          shape="rounded"
          onChange={handlePageChange}
        />
      </div>
    </Container>
  );
};

export default Index;
