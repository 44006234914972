import * as React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Index from './pages/Index'
import ProductView from './pages/ProductView'
import './App.css';

const App = () => {
    return (
            <BrowserRouter>

                <Routes>
                    <Route exact path="./" element={<Index/>} />
                    <Route path="/product/:plu" element={<ProductView/>} />
                </Routes>
            </BrowserRouter>
    );
};

export default App;