import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Pagination,
  Container,
  Typography,
  Divider,
  Paper,
  Toolbar,
  AppBar,
  Button,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

const ProductView = () => {
  const { product } = useLocation().state;
  const navigate = useNavigate();
  const [offer, setOffer] = React.useState(false);

  React.useEffect(() => {
    console.log(product);
    if (product.precioPromo > 0) setOffer(true);
  }, []);

  return (
    <>
      <AppBar sx={{ position: "sticky" }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <ArrowBackIcon
            onClick={() => {
              navigate(-1);
            }}
          />
        </Toolbar>
      </AppBar>
      <Container>
        <h1>{product.productName}</h1>
        <Paper
          elevation={3}
          sx={{
            margin: "auto",
            marginBottom: "30px",
            width: "15rem",
            padding: "3%",
          }}
        >
          <img
            src={`https://spx.rtitec.com.ar:8080/api/products/image/${product.productPLU}/front`}
            alt={product.productName}
            style={{
              width: "100%",
            }}
            onError={(e) => {
              e.target.src = "https://user-images.githubusercontent.com/24848110/33519396-7e56363c-d79d-11e7-969b-09782f5ccbab.png";
            }}
          />
        </Paper>
        <Typography variant="body1">{product.productDesc}</Typography>
        <Typography variant="body1">
          {product.cantidadUnitaria} {product.unidad}
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            fontSize: "x-large",
            marginTop: "30px",
          }}
        >
          {offer ? (
            <>
            <div>
              <span
                style={{
                  marginRight: "10px",
                  textDecoration: "line-through",
                  fontWeight: "100",
                }}
              >
                ${product.precioVenta}
              </span>
              <span
                style={{
                  color: "#F9A825",
                  fontWeight: "bolder",
                  fontSize: "inherit",
                }}
              >
                Oferta!
              </span>
            </div>
          <h1 style={{ margin: "0", color: "#F9A825" }}>
            ${product.precioPromo}
          </h1>
          </>
          ) : (
          <h1 style={{ margin: "0", color: "#1976d2" }}>
            ${product.precioVenta}
          </h1>
          )}
          <Button
            variant="outlined"
            color="warning"
            sx={{ width: "30%", marginLeft: "auto" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Volver
          </Button>
        </div>
      </Container>
    </>
  );
};

export default ProductView;
